<template>
  <div class="container con bg df">
<!--    <div class="left">-->
<!--      <div class="tip">-->
<!--        <div class="tip_title">文本生成图像</div>-->
<!--        <div class="tip_con"> 剩余AI绘画次数：<span style="color: rgba(13, 225, 179, 1);">{{this.info.draw_number }}</span>&nbsp;次</div>-->
<!--      </div>-->

<!--      <div class="option">-->
<!--        <div class="title" style="margin-left: 10px;">绘画风格</div>-->
<!--        <div class="list">-->
<!--          <div class="sortBox" :class="idx == cur ? 'act' : ''" v-for="(i, idx) in list" :key="i.value" @click="setStyle(i, idx)">-->
<!--            {{ i.label }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="option">-->
<!--        <div class="title" style="margin-top: 20px; margin-left: 10px;">尺寸分辨率</div>-->
<!--        <div class="list">-->
<!--          <div class="sortBox rslt" :class="idx == type ? 'act' : ''" v-for="(i, idx) in resolution" :key="i" @click="setSize(idx)">-->
<!--            {{ i }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="option">-->
<!--        <div class="title" style="margin-left: 10px;">正向提示词</div>-->
<!--        <div class="ipt">-->
<!--          <el-input style="margin-left: 10px;" type="textarea" resize="none" maxlength="300" show-word-limit placeholder="请输入你心中的画面，尽量描述具体~" v-model="text"> </el-input>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="option">-->
<!--        <div class="title" style="margin-left: 10px;">反向提示词</div>-->
<!--        <div class="list">-->
<!--          <el-input style="margin-left: 10px;" type="textarea" resize="none" maxlength="300" show-word-limit placeholder="请输入你心中的画面，尽量描述具体~" v-model="oppositetext"> </el-input>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="desc_create">-->
<!--        <el-button type="primary" @click="draw">点击生成</el-button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="right">
      <img v-show="!url && type == 0" :src="baseSrc" alt="" style="width: 250px; height: 250px"/>
      <div class="creating_div">功能正在开发中，请耐心等待哦~</div>
    </div>
  </div>
</template>
<script>
import { draw, preserve_draw, getUser } from "@/api/user.js";
import { download } from "@/utils/tools";

let loading;
export default {
  name: "aiPPT",
  data() {
    return {
      index: 0,
      type: 0,
      cur: null,
      text: "",
      oppositetext: "",
      url: "",
      baseSrc: require("../../assets/img/creating.png"),
      info: {},
      list: [
        {
          label: "水墨画",
          value: 101,
        },
        {
          label: "概念艺术",
          value: 102,
        },
        {
          label: "油画1",
          value: 103,
        },
        {
          label: "油画2（梵高）",
          value: 118,
        },
        {
          label: "水彩画",
          value: 104,
        },
        {
          label: "像素画",
          value: 105,
        },
        {
          label: "厚涂风格",
          value: 106,
        },
        {
          label: "插图",
          value: 107,
        },
        {
          label: "剪纸风格",
          value: 108,
        },
        {
          label: "印象派1（莫奈）",
          value: 109,
        },
      ],
      resolution: ["1:1 (768:768)", "3:4 (768:1024)", "4:3 (1024:768)"],
    };
  },
  mounted() {
    this.info = JSON.parse(localStorage.getItem("userInfo"));
  },
  methods: {
    setSize(idx) {
      this.type = idx;
    },
    setStyle(i, idx) {
      this.styles = i.value;
      this.cur = idx;
    },
    draw() {
      loading = this.$loading({
        lock: true,
        text: "正在绘画",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.info.draw_number != 0){
        draw({
        styles: this.styles,
        text: this.text,
        type: this.type + 1,
        oppositetext: this.oppositetext,
      }).then((res) => {
        loading.close();
        console.log(this.info.draw_number)
        if (res.code == "200") {
          this.url = res.data.url;
          getUser({ token: localStorage.getItem("token") }).then((res1) => {
            if (res1.code == "200") {
              this.info = res1.data;
            }
          });
          console.log(res.msg)
          this.$message.success("生成成功");
        } else {
          this.$message.error("生成失败请联系管理员",res.msg);
          console.log(res.msg);
        }
      });
      }else {
        loading.close();
        this.$message.error("生成次数不足请充值！！！");
      }
    },
    downloadPic() {
      console.log(this.url, this.text);
      download(this.url, this.text);
    },
    save() {
      preserve_draw({
        url: this.url,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.con {
  padding: 30px 40px;
  .left {
    position: relative;
    width: 900px;
    height: 810px;
    opacity: 1;
    border-radius: 16px;
    background: rgba(32, 37, 50, 1);
    padding: 20px 40px 30px 10px;
    text-align: left;

    .tip {
      position: relative;
      left: -10px;
      top: -20px;
      width: 900px;
      height: 60px;
      opacity: 1;
      border-radius: 16px 16px 0px 0px;
      background: rgba(45, 50, 64, 1);
      padding: 17px 20px;
      margin-bottom: 10px;
      .tip_title{
        float: left;
        left: 27px;
        top: 17px;
        width: 108px;
        height: 27px;
        opacity: 1;
        /** 文本1 */
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 26.06px;
        color: rgba(13, 225, 179, 1);
        text-align: left;
        vertical-align: top;
      }
      .tip_con{
        position: relative;
        left: 601px;
        top: 0px;
        width: 300px;
        height: 24px;
        opacity: 1;
        /** 文本1 */
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 0.8);
        text-align: left;
        vertical-align: top;
      }
    }

    .option {
      margin-bottom: 15px;
      .title {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 10px;
      }
      .list {
        border-radius: 100px;
        display: flex;
        flex-wrap: wrap;

        .sortBox {
          margin-left: 10px;
          margin-right: 20px;
          border-radius: 8px;
          background: rgba(35, 40, 48, 1);
          padding: 6px 8px;
          margin-top: 15px;
          border: 1px solid rgba(67, 73, 89, 1);
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(255, 255, 255, 1);
        }

        .rslt {
          width: 257px;
          text-align: center;
          margin-right: 10px;
        }
        .rslt:last-child {
          margin-right: 0px;
        }

        .act {
          background: rgba(25, 74, 249, 1);
          border: 1px solid rgba(25, 74, 249, 1);
        }
      }

      .ipt {
        /*box-shadow: 0px 1px 5px #bdd3ed;*/
      }
    }
  }
  .desc_create{
    /deep/.el-button--primary{
      margin-left: 35%;
      margin-top: 20px;
      width: 264px;
      height: 48px;
      border: none;
      color: rgba(2, 38, 30, 1);
      background: linear-gradient(90deg, rgba(54, 243, 170, 1) 0%, rgba(4, 221, 181, 1) 100%) !important;
    }
  }
  .btns {
      width: 98%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      /deep/.el-button--primary{
        width: 160px;
        height: 48px;
        color: rgba(2, 38, 30, 1);
        border-radius: 10px;
        background: linear-gradient(90deg, rgba(54, 243, 170, 1) 0%, rgba(4, 221, 181, 1) 100%) !important;
        border: 1px solid rgba(45, 50, 64, 1) !important;
      }
    }
  .right {
    margin-left: 80px;
    margin-top: 40px;
    width: 1520px;
    height: 750px;
    opacity: 1;
    border-radius: 16px;
    background: rgba(32, 37, 50, 1);
    padding: 20px 40px 30px;
    img {
      margin-top: 100px;
      width: 100%;
      height: 100%;
    }
    .creating_div{
        margin: auto;
        color: white;
        padding-top: 50px;
        width: 396px;
        height: 28px;
        font-family: Microsoft YaHei;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
    }
  }
}

/deep/.el-textarea__inner {
  width: 840px;
  height: 140px;
  opacity: 1;
  border-radius: 10px;
  background: rgba(15, 19, 25, 1);
  border: 1px solid rgba(45, 50, 64, 1);
  color: white;
}
/deep/.el-textarea .el-input__count{
  background: none;
}
</style>
